body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-container {
  padding-right: 10%;
  padding-left: 10%;
  padding-top: 0%;
  padding-bottom: 5%;
}
  
.inner-app-wrapper{
  padding: 2%;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  color: rosybrown;
}

.link{
  color: black;
  text-decoration: none;
  font-weight: bold;
}

.link:visited{
  color: black;
}

.link:hover {
color: rosybrown;
}

.link-to-website{
  color: black;
  margin-right: 2%;
  font-weight: bold;
  text-decoration: underline;
}

.link-to-website ~ .link-to-website  {
  margin-left: 2%;
}

.link-to-website:visited{
  color: black;
}

.link-to-website:hover {
color: rosybrown;
}

.links-to-websites{
  display: flex;
  justify-content: left;
}

@media screen and (max-width: 801px){
  .links-to-websites{
      margin-left: 5%;
  }
}

@media screen and (max-width: 800px){
  .links-to-websites{
      margin-bottom: 15%;
      justify-content: start;
      margin-right: 15%;
  }

  .link-to-website ~ .link-to-website  {
    margin-left: 2%;
  }
}

@media screen and (max-width: 375px){
  .links-to-websites{
      margin-bottom: 15%;
      justify-content: start;
      margin-left: 0;
      font-size: small; 
  }
}

.resume-publication-and-project-link {
  text-decoration: underline;
  font-weight: normal;
  margin-left: .5%;
  margin-right: .5%;
}

.heading{
  margin-top: 2%;
  text-align: center;
}

/* style for projects and publications */

.projects-and-publications-heading {
  margin-bottom: 5%;
  text-align: center;
}

.titles-for-project-and-publication-index{
  color: black;
  text-decoration: none;
  font-weight: bold;
  text-align: center
}

.titles-for-project-and-publication-index:visited{
  color: black;
}

.titles-for-project-and-publication-index:hover{
  color: rosybrown;
}

.image-container {
  border: solid black;
  padding: 10px;
}

.project-image{ 
  max-width: 200px;
  cursor: pointer;
  margin-top: 10px;
}

@media screen and (max-width: 900px){
  .project-image{
    max-width: 75%;
  }
}

@media screen and (max-width: 900px){
  .image-container {
    border: none;
    padding: none;
  }
}

.project-show-pages{
text-align: center
}

.project-display-image {
  max-width: 375px;
}

@media screen and (max-width: 900px){
  .project-display-image{
    width: 75%;
    max-width: none;
  }
}

.project-description{
  font-size: medium;
  text-align: left;
  max-width: 450px;
  margin-top: 0px;
 }
 
 @media screen and (max-width: 900px){
   .project-description{
       text-align: left;
       margin-left: 7%;
       margin-right: 10%;
       margin-top: 3%;
       margin-bottom: 0px;
   }
 }
 
 @media screen and (max-width: 375px){
   .project-description{
       text-align: left;
       margin-left: 0;
       margin-right: 0;
   }
 }

.container-for-project-display{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%
}

.project-link {
  margin-right: 5%;
}

.project-display-image-and-links {
  display: flex;
}

@media screen and (max-width: 900px){
  .project-display-image-and-links{
      display: flex;
      flex-direction: column;
      justify-content: center;
  }
}

.project-links-to-websites{
  display: flex;
  justify-content: left;
}

@media screen and (max-width: 900px){
  .project-links-to-websites{
    display: flex;
    justify-content: left;
    margin-right: 0%;
  }
}

.container-for-publication-and-project-index{
  text-align: center;
}

@media screen and (max-width: 900px){
  .container-for-publication-and-project-index{
      text-align: center;
      display:flex;
      justify-content: center;
      flex-direction: column;
  }
}

.heading-for-publication-showpage{
  text-align: center;
  margin-top: 5%   
}

.publications-and-projects-container{
  display: flex;
  margin-top: 5%;
  justify-content: space-evenly;
  margin-bottom: 5%
} 

.publication-and-project-tile{
  margin-bottom: 2%;
  margin-top: 2%;
  padding: 10px;
}

@media screen and (max-width: 900px){
  .publications-and-projects-container{
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-top: 0%;

  }
}

@media screen and (max-width: 900px){
  .publication-and-project-tile{
     margin-bottom: 2%;
     margin-top: 2%;
     padding: none;
  }
}

.book-image{
  width: 100%;
  height: 80%;
  cursor: pointer;
}

@media screen and (max-width: 800px){
  .book-image{
      width: 80%;
      height: 80%;
      text-align: center;
  }
}

.book-container-on-show-page{
  display: flex;
  justify-content: center;
  flex-direction: row
}

@media screen and (max-width: 800px){
  .book-container-on-show-page{
      display: flex;
      justify-content: center;
      flex-direction: column;
  }
}

.book-image-on-show-page{
  width: 100%;
  margin-left: 10%;
  margin-right: 10%;  
  margin-top: 5%;
}

@media screen and (max-width: 800px){
  .book-image-on-show-page{
      width: 80%;
      height: 80%;
  }
}

.book-description{
  margin-top: 2%;
  text-align: left;
  width: 25%;
  height: 35%;
  margin-left: 10%;
  margin-top: 2%;
}

@media screen and (max-width: 800px){
  .book-description{
      width: 80%;
      height: 80%;
      margin-top: 2%;
      margin-right: 5%;
      margin-left: 10%
  }
}

/* style for about page */

.container-for-about-page{
  display: flex;
  justify-content: center;
  margin-bottom: 5%
}

@media screen and (max-width: 800px ){
  .container-for-about-page{
  text-align: center;
  display: flex;
  flex-direction: column
  } 
} 

@media screen and (max-width: 800px){
  .about-page{
     text-align: center
  }
}

.profile-photo{
  width: 30%;
  height: 60%;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 1%;
}

@media screen and (max-width: 950px){
  .profile-photo{
    width: 45%;
    height: 80%;
  }
}

@media screen and (max-width: 800px){
  .profile-photo{
    text-align: center;
    width: 60%;
    margin-right: 20%;
    margin-left: 15%;
  }
}

@media screen and (max-width: 375px){
  .profile-photo{
    text-align: center;
    width: 80%;
    margin-right: 0;
    margin-left: 10%;
  }
}

.second-container-for-about-page {
  text-align: left;
  margin-left: 7%;
  display: flex;
  flex-direction: column;

}

@media screen and (max-width: 800px){
  .second-container-for-about-page{
      text-align: center;  
}
}

.second-container-for-project-page {
  text-align: left;
  margin-left: 7%;
  display: flex;
  flex-direction: column;
}

.second-container-for-project-page:first-child {
  margin-top: 1.5%;
}

@media screen and (max-width: 800px){
  .second-container-for-project-page{
      text-align: center;  
      margin-top: 5px;
}
}

@media screen and (min-width: 801px){
  .profile-description{
      text-align: left;
  }

  .profile-description > *{
    margin-top: 1.5%;
  }
}

@media screen and (max-width: 800px){
  .profile-description{
      text-align: left;
      margin-right: 15%;
      margin-left: 5%;
  }

  .profile-description:first-child{
    margin-top: 3%;
  }
}

@media screen and (max-width: 375px){
  .profile-description{
      text-align: left;
      margin-left: 0;
      margin-right: 0;
  }

  .profile-description:first-child{
    margin-top: 2%;
  }
}

/* style for resume */

.resume-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.resume-header-container {
  display: flex;
  flex-direction: column;
}

.resume-header {
  margin-bottom: 0;
}

.resume-section-header {
  border-bottom: 2px solid;
  padding-bottom: 1%;
  margin-right: 1%;
  margin-bottom: 1%;
}

.resume-section-bold {
  font-weight: bold;
}

.resume-paragraph {
  margin-top: 0;
  margin-bottom: 2%;
  font-size: 90%;
  padding-right: 5%;
}

@media screen and (max-width: 700px){
  .resume-paragraph {
    font-size: small;
  }
}

.resume-date {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 90%;
  width: 20%;
}

@media screen and (max-width: 700px){
  .resume-date {
    font-size: small;
  }
}

.resume-links {
  justify-content: center;
  margin: 0;
}

.link-for-resume-and-projects {
  font-weight: normal;
  margin: 1%;
}

.li-resume {
  margin-bottom: 1%;
  margin-right: 5%;
  font-size: 90%;
  margin-top: 0%;
}

@media screen and (max-width: 500px){
  .li-resume {
    margin-right: 0%;
    margin-bottom: 3%;
    font-size: small;
  }
}

table {
  width: 100%;
}

.project-title {
  margin-top: 2%;
  margin-bottom: 0;
  font-size: 90%;
  padding-right: 5%;
}

@media screen and (max-width: 700px){
  .project-title {
    font-size: small;
    margin-top: 5%;;
  }
}

.project-title-first {
  margin-top: 2%;
  margin-bottom: 0;
  font-size: 90%;
  padding-right: 5%;
}

@media screen and (max-width: 700px){
  .project-title-first {
    font-size: small;
  }
}

@media screen and (min-width: 701px){
  .project-description-on-resume{
    font-size: 90%;
    margin-top: 0%;
    margin-bottom: 2%;
    padding-right: 5%;
  }
}
@media screen and (max-width: 700px){
  .project-description-on-resume{
    font-size: small;
    margin-top: 2%;
    margin-bottom: 2%;
  }
}

/* navigation bar */
.nav-bar-wrapper {
  display: flex;
  justify-content: center; 
}

@media screen and (max-width: 400px){
  .nav-bar-wrapper {
  font-size: small;

  }
}

.nav-bar-items {
  border-color: black;
  border-width: 5px 0px;
  border-style: solid;
  width: 100%;
  padding: 1em;
  margin: 0em;
  background: azure;
  text-align: center;
  color: black;
  text-decoration: none;
}

.nav-bar-items:active {
  cursor: pointer;
  color: rosybrown;
  text-decoration: none;
  color: black;
}

.nav-bar-items:hover {
  cursor: pointer;
  color: rosybrown;
}
